export const educationDatabase = [
    {title: 'Software Engineering', degree: 'Bachelor`s degree', school: 'Oslo Metropolitan University', year: '2022 - Present', description: 'The program covers a wide range of topics within Computer Science and Software Engineering, such as mathematics, programming, algorithms and datastructures, databases, web development, app development, software testing, cyber security, computer networks, computer architecture, operating systems and more.'},
    {title: 'Pre-Engineering', degree: '1-year course', school: 'Oslo Metropolitan University', year: '2019 - 2020', description: 'Required to be able to apply for the Engineering program.'},
    {title: 'Computer Electronics', degree: 'Trade Certificate', school: 'Elvebakken VGS, Sogn VGS and Intility', year: '2009 - 2014', description: 'The first three years covered topics such as electrical systems, electronics, programming, servers, computer hardware, computer architecture and computer networks. The last 18 months were an apprenticeship at Intility, where I worked as an IT support technician.'},
];

export const experienceDatabase = [
    {title: 'Incident Responder', company: 'Intility', year: '2017 - 2022', description: 'Operations, monitoring and incident management of computer networks, servers and a wide range of business applications and services. Training of technicians in monitoring and incident management. Worked part-time from August 2019 to December 2020 when I was studying.'},
    {title: 'Senior Technician', company: 'Intility', year: '2015 - 2017', description: 'Distribute tickets, help other support technicians and handle more complex tickets. Training of new employees. 3 months in Auckland and 12 months at Intility`s office in Singapore. Support, monitoring and incident management. On-site support for customer in Singapore and onboarding of new customer in Hong Kong.'},
    {title: 'Technician', company: 'Intility', year: '2014 - 2015', description: 'Technical support with specialization in the business applications of Intility`s customers. Training of new employees.'},
    {title: 'Technical Apprentice', company: 'Intility', year: '2012 - 2014', description: 'Technical support via phone, e-mail and chat.'},
];

export const projectDatabase = [
    {title: 'Map Web Application', github: '', url: '', organization: 'Oslo Metropolitan University', year: '2024 - Present', description: 'This is a school project we are currently working on. We are building a full stack web application using Google Maps API and building functionality on top of the map to save markers on the map, including comments, images, reviews etc.', technologies: ['React', 'Bootstrap', 'ASP.NET Core', 'EF Core', 'MySQL']},
    {title: 'vetlelg.com', github: 'https://github.com/vetlelg/vetlelg-frontend', url: 'https://vetlelg.com', organization: 'Personal project', year: '2024', description: 'The frontend of my portfolio website', technologies: ['React', 'Bootstrap', 'CSS', 'Azure'] },
    {title: 'DRTP', github: 'https://github.com/vetlelg/drtp', url: '', organization: 'Oslo Metropolitan University', year: '2024', description: 'This project implements a reliable transport protocol similar to TCP on top of UDP to create a simple reliable file transfer application in Python. The functionalities of the project include a three-way handshake for connection establishment, Go-Back-N sliding window protocol for data transmission and a two-way handshake for connection teardown. The application reads a file from the client, sends it over the network to the server and writes it to a file on the server.', technologies: ['Python', 'Socket library', 'UDP']},
    {title: 'Spring Boot school projects', github: 'https://github.com/vetlelg/springboot-projects', url: '', organization: 'Oslo Metropolitan University', year: '2023', description: 'School projects in webprogramming. Some simple full stack web applications.', technologies: ['Java', 'Spring Boot', 'MySQL', 'JavaScript', 'Bootstrap'] },
    {title: 'Responsive Web Design', github: 'https://github.com/vetlelg/final-assignment', url: '', organization: 'Oslo Metropolitan University', year: '2022', description: 'School project in responsive web design using only HTML and CSS.', technologies: ['HTML', 'CSS']},
    {title: 'The Fountain of Objects', github: 'https://github.com/vetlelg/csharpplayersguide/tree/main/bossbattles/TheFountainOfObjects', url: '', organization: 'Personal Project', year: '2021', description: 'A fun and simple console game I made when learning C#', technologies: ['C#']},
    {title: 'Snake', github: 'https://github.com/vetlelg/sfml-snake', url: '', organization: 'Personal Project', year: '2017', description: 'My first object-oriented programming project I created when trying to learn C++', technologies: ['C++', 'SFML']},
];